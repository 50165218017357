import React, {useContext, useEffect, useRef, useState} from "react";
import {withBasicProviders} from "../../provider/withBasicProviders";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import useFetch from "use-http";
import {API} from "../../config";
import AddIcon from "@mui/icons-material/Add";
import TableChartIcon from "@mui/icons-material/TableChart";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";

import AuthProvider, {authContext, useAuth} from "../../provider/AuthProvider";
import Header from "../../components/Header";
import SnackProvider, {snackProvider} from "../../provider/SnackProvider";

import {columns} from "./columns";
import QueueDialog from "./QueueDialog";
import DataGridContainer from "./DataGridContainer";

function Creators() {
  const inputFileRef = useRef();
  const {triggerSnackbar} = useContext(snackProvider);
  const {auth, authed} = useContext(authContext);
  const [data, setData] = useState([]);
  const [importQueue, setImportQueue] = useState([]);

  const options = {
    cachePolicy: "no-cache",
    headers: {
      Authorization: `Basic ${auth}`,
    },
  };

  const {loading, error, post, put, get, del} = useFetch(
    `${API}/manage/creators`,
    options
  );

  async function fetchCreators() {
    const result = await get();
    if (!result?.creators) {
      return;
    }
    setData([...result.creators]);
  }

  useEffect(() => {
    fetchCreators();
  }, []);

  async function executePost(payload) {
    const response = await post("/", payload);

    if (!response.success) {
      return false;
    }

    return response.creator;
  }

  async function executeDel(id) {
    const response = await del(`/${id}`);

    if (response !== "") {
      return false;
    }

    return true;
  }

  async function executePut(id, payload) {
    const response = await put(`/${id}`, payload);

    if (!response.success) {
      return false;
    }

    return response.creator;
  }

  async function processRowUpdate(newRow, oldRow) {
    const creator = await executePut(oldRow.id, newRow);

    if (!creator) {
      triggerSnackbar({message: "Hoppla. Fehler!!"});
      return oldRow;
    }

    triggerSnackbar({message: "Done, updated!!"});
    return creator;
  }

  async function addRow() {
    const creator = await executePost({username: `${Math.random()}`});

    if (!creator) {
      triggerSnackbar({message: "Hoppla. Fehler!!"});
      return false;
    }
    triggerSnackbar({message: "Done, added!!"});

    setData([creator, ...data]);
  }

  async function delRow(id) {
    const success = await executeDel(id);

    if (!success) {
      triggerSnackbar({message: "Hoppla. Fehler!!"});
      return false;
    }

    fetchCreators();

    triggerSnackbar({message: "Success, deleted!!"});
  }

  function onFileChangeCapture(e) {
    var f = e.target.files[0];
    if (f) {
      var r = new FileReader();
      r.onload = function (e) {
        const lines = e.target.result.split("\n");
        const todo = lines
          .map((l, i) => {
            const fields = l.split(";");

            if (i === 0) {
              return null;
            }
            if (fields.length < 3) {
              return null;
            }

            return {
              email: fields[0].replaceAll(" ", ""),
              socialname: fields[1].replaceAll('"', ""),
              username: fields[2].replace(/[\W_]/g, ""),
            };
          })
          .filter((l) => !!l);
        setImportQueue(todo);
      };
      r.readAsText(f);
    } else {
      alert("Failed to load file");
    }
  }

  async function importingCsv() {
    const results = await Promise.all(
      importQueue.map(async (creator) => {
        const response = await post("/", creator);

        return response.success;
      })
    );

    const success = results.filter((r) => r === true).length;

    triggerSnackbar({
      message: `Erfolgreich importiert: ${success} / ${results.length}.`,
    });
    resetImportQueue();
    await fetchCreators();
  }

  function resetImportQueue() {
    setImportQueue([]);
  }

  if (!authed) {
    return null;
  }

  if (data.length === 0) {
    return <LinearProgress />;
  }

  return (
    <>
      <Header />
      <input
        accept=".csv"
        type="file"
        ref={inputFileRef}
        onChangeCapture={onFileChangeCapture}
        style={{display: "none"}}
      />
      <QueueDialog
        queue={importQueue}
        handleSuccess={importingCsv}
        handleClose={resetImportQueue}
      />

      <DataGridContainer
        addRow={addRow}
        delRow={delRow}
        data={data}
        processRowUpdate={processRowUpdate}
        triggerCsvImportDialog={() => inputFileRef.current.click()}
      />
    </>
  );
}

export default withBasicProviders(AuthProvider, SnackProvider)(Creators);
