import React, {useContext, useEffect, useState} from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import styled from "@emotion/styled";
import logo from "../../assets/logo-blue-green.svg";
import AuthProvider, {authContext} from "../provider/AuthProvider";
import {withBasicProviders} from "../provider/withBasicProviders";
import {useNavigate} from "react-router-dom";
import styled from "@emotion/styled";

const StyledImg = styled.img`
  height: 2em;
  margin-right: 2em;
`;

function Header(props) {
  const navigate = useNavigate();
  const {authed, logout} = useContext(authContext);

  if (!authed) {
    return null;
  }

  const menu = [
    {
      title: "Inquiries",
      path: "/projects",
    },
    {
      title: "Creators",
      path: "/creators",
    },
    {
      title: "Flow",
      path: "/flow",
    },
    {
      title: "Stats",
      path: "/stats",
    },
  ];

  return (
    <AppBar position="fixed" sx={{height: 64}}>
      <Container>
        <Toolbar disableGutters>
          <a href="/">
            <StyledImg src={logo} />
          </a>

          <Box sx={{flexGrow: 1, display: "flex"}}>
            {menu.map((item) => (
              <Button
                variant={location.pathname === item.path ? "outlined" : "text"}
                key={item.title}
                onClick={() => navigate(item.path)}>
                {item.title}
              </Button>
            ))}
          </Box>
          <Button color="secondary" onClick={() => logout()}>
            Logout
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default withBasicProviders(AuthProvider)(Header);
