import React, {useContext, useEffect, useRef, useState} from "react";
import {withBasicProviders} from "../../provider/withBasicProviders";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import useFetch from "use-http";
import {API} from "../../config";
import AddIcon from "@mui/icons-material/Add";
import TableChartIcon from "@mui/icons-material/TableChart";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";

import AuthProvider, {authContext, useAuth} from "../../provider/AuthProvider";
import Header from "../../components/Header";
import SnackProvider, {snackProvider} from "../../provider/SnackProvider";

import {columns} from "./columns";
import QueueDialog from "./QueueDialog";

export default function DataGridContainer(props) {
  const {data, addRow, delRow, processRowUpdate, triggerCsvImportDialog} =
    props;
  return (
    <Grid container sx={{height: "calc(100vh - 128px)", marginTop: "64px"}}>
      <Grid item xs={12}>
        <Box
          sx={{
            height: "calc(100vh - 64px)",
            width: "100%",
            "& .MuiDataGrid-row--editing .MuiDataGrid-cell": {},
            "& .MuiDataGrid-row--editing .MuiDataGrid-cell--editing": {
              bgcolor: (theme) => `${theme.palette.secondary.dark} !important`,
            },
          }}>
          <DataGrid
            experimentalFeatures={{newEditingApi: true}}
            initialState={{
              sorting: {
                sortModel: [{field: "createdAt", sort: "desc"}],
              },
            }}
            rows={data}
            columns={columns(delRow)}
            editMode={"row"}
            hideFooterPagination
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: () => (
                <GridToolbarContainer>
                  <Button
                    size="small"
                    onClick={() => addRow()}
                    startIcon={<AddIcon />}>
                    Add
                  </Button>
                  <GridToolbarColumnsButton />
                  <GridToolbarFilterButton />
                  <GridToolbarDensitySelector />
                  <GridToolbarExport />

                  <Button
                    size="small"
                    onClick={triggerCsvImportDialog}
                    startIcon={<TableChartIcon />}>
                    .csv import
                  </Button>
                </GridToolbarContainer>
              ),
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
