import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

export function columns(deleteFn = () => {}) {
  return [
    {
      field: "id",
      headerName: "Id",
      editable: false,
      flex: 0.4,
    },

    {
      field: "socialname",
      headerName: "Socialname",
      editable: true,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      editable: true,
      flex: 1,
    },
    {
      field: "username",
      headerName: "Username",
      editable: true,
      flex: 0.5,
    },
    {
      field: "open",
      headerName: "Open",
      editable: false,
      flex: 0.2,
      renderCell: (params) => (
        <IconButton
          href={`https://go.fillter.me/${params.row.username}`}
          target="_blank">
          <ExitToAppIcon />
        </IconButton>
      ),
    },
    {
      field: "createdAt",
      headerName: "createdAt",
      type: "date",
      editable: false,
      flex: 0.6,
      valueFormatter: (params) => valueFormatter(params),
    },
    {
      field: "updatedAt",
      headerName: "updatedAt",
      type: "date",
      editable: false,
      flex: 0.6,
      valueFormatter: (params) => valueFormatter(params),
    },
    {
      field: "del",
      headerName: "Del",
      editable: false,
      flex: 0.2,
      renderCell: (params) => (
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => {
            if (!confirm(`Really delete ${params.row.username}?`)) {
              return false;
            }

            deleteFn(params.row.id);
          }}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
}

function valueFormatter(params) {
  if (!params.value || new Date(params.value).getTime() < 0) {
    return "-";
  }
  return new Intl.DateTimeFormat("de-DE", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(new Date(params.value));
}
