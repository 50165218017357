import React, {useState, useEffect, useContext} from "react";
import useFetch from "use-http";

import base64 from "base-64";
import {API} from "../config";

function AuthProvider({children}) {
  const [auth, setAuth] = useState(localStorage.getItem("auth"));

  const options = {
    headers: {
      Authorization: `Basic ${auth}`,
    },
  };
  const {loading, error, data} = useFetch(`${API}/token`, options, [auth]);

  function login(user, pass) {
    setAuth(base64.encode(`${user}:${pass}`));
    localStorage.setItem("auth", base64.encode(`${user}:${pass}`));
    location.reload();
  }

  function logout() {
    setAuth("");
    localStorage.setItem("auth", "");
    location.reload();
  }

  const authed = data === true;

  return (
    <authContext.Provider
      value={{
        authed,
        auth,
        login,
        logout,
      }}>
      {children}
    </authContext.Provider>
  );
}

export default AuthProvider;
export const authContext = React.createContext();
