import React, {useContext, useEffect, useState} from "react";
import {getEntry} from "../contentful";

function useTree(props) {
  const [tree, setTree] = useState({});
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("debug") === "1") {
      setTree(JSON.parse(localStorage.getItem("tree")));
      return;
    }

    async function buildTree(id) {
      let entry = null;
      try {
        entry = await getEntry(id);
      } catch (error) {
        const link = `https://app.contentful.com/spaces/${process.env.CONTENTFUL_SPACE}/entries/${id}`;
        console.error("getEntry", link);
        console.error(error);
        setErrors([...errors, link]);
      }

      if (!entry) {
        return null;
      }

      let children = [];
      if (entry.fields.choices) {
        const childPromises = entry.fields.choices.map(async (choice) => {
          return await buildTree(choice.sys.id);
        });
        children = await Promise.all(childPromises);
      }

      const name = entry.fields?.title;
      const questions = entry.fields?.questions?.map((e) => ({
        id: e.sys.id,
        title: e?.fields?.title ?? "missing title",
        inquiryCategory: e?.fields?.inquiryCategory?.fields?.title,
      }));

      const inquiryCategory = entry.fields?.inquiryCategory?.fields?.title;
      const inquiryType = entry.fields?.inquiryType;

      if (!inquiryCategory) {
        console.error(
          "Missing inquieryCategory:",
          `https://app.contentful.com/spaces/${process.env.CONTENTFUL_SPACE}/entries/${id}`
        );
      }

      return {
        id,
        name,
        questions,
        children,
        inquiryCategory,
        inquiryType,
      };
    }

    (async () => {
      const tree = await buildTree(`${process.env.CONTENTFUL_PAGE_FORM}`);
      setTree(tree);
      window.tree = tree;
    })();
  }, []);

  return {tree, errors};
}
export default useTree;
