import React from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {Button, IconButton} from "@mui/material";

export default function QueueDialog(props) {
  const {queue, handleSuccess, handleClose} = props;
  return (
    <Dialog
      fullScreen
      open={queue.length > 0}
      onClose={handleClose}
      TransitionComponent={Transition}>
      <AppBar sx={{position: "relative"}}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            Gelesene Datensätze:
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSuccess}>
            Importieren
          </Button>
        </Toolbar>
      </AppBar>
      <List>
        {queue.map((creator, i) => (
          <div key={i}>
            <ListItem>
              <ListItemText
                primary={`username: ${creator.username}`}
                secondary={`socialname: ${creator.socialname}, email: ${creator.email}`}
              />
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
