import React, {useContext, useEffect, useState} from "react";

import {withBasicProviders} from "../provider/withBasicProviders";

import AuthProvider, {authContext, useAuth} from "../provider/AuthProvider";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import {useNavigate} from "react-router-dom";

function Login(props) {
  const [user, setUser] = useState(null);
  const [pass, setPass] = useState(null);
  const navigate = useNavigate();
  const {authed, login} = useContext(authContext);

  if (authed) {
    navigate("/");
    return null;
  }
  return (
    <Box sx={{width: 500, margin: "auto", marginTop: "250px"}}>
      <Alert variant="outlined" severity={"error"}>
        <AlertTitle>Please log in</AlertTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            login(user, pass);
          }}>
          <TextField
            onChange={(e) => setUser(e.target.value)}
            type={"text"}
            label="user"
            sx={{m: 1, width: "50ch"}}
          />
          <TextField
            onChange={(e) => setPass(e.target.value)}
            type={"password"}
            label="pass"
            sx={{m: 1, width: "50ch"}}
          />

          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{m: 1, width: "25ch"}}>
            Login
          </Button>
        </form>
      </Alert>
    </Box>
  );
}

export default withBasicProviders(AuthProvider)(Login);
