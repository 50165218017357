import React, {useContext, useEffect, useState} from "react";
import {withBasicProviders} from "../provider/withBasicProviders";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import useFetch from "use-http";
import {API} from "../config";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import AuthProvider, {authContext} from "../provider/AuthProvider";
import Header from "../components/Header";

const columns = [
  {
    field: "entity",
    headerName: "Marke",
    editable: false,
    flex: 1.5,
  },
  {
    field: "creatorname",
    headerName: "Creator",
    editable: false,
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "createdAt",
    type: "date",
    editable: false,
    flex: 1.5,
    valueFormatter: (params) =>
      new Intl.DateTimeFormat("de-DE", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(new Date(params.value)),
  },
];

function dataToRows(projects) {
  return projects
    .filter((e) => e.creatorname != "klaus")
    .map((e) => ({
      id: e.id,
      entity: e.brand.entity,
      creatorname: e.creatorname,
      createdAt: e.createdAt,
    }));
}

function Projects(props) {
  const {auth, authed} = useContext(authContext);
  const [selectionModel, setSelectionModel] = React.useState([]);

  const options = {
    headers: {
      Authorization: `Basic ${auth}`,
    },
  };

  const {loading, error, data} = useFetch(`${API}/projects`, options, []);

  if (!authed) {
    return null;
  }
  if (!data?.success) {
    return <LinearProgress />;
  }

  console.log(data);
  console.log(selectionModel);
  const id = selectionModel[0];
  const project = data.projects.filter((e) => e.id === id)[0];
  const brand = project?.brand;
  const inquiry = project?.inquiry;
  console.log(project);
  console.log(brand);

  return (
    <>
      <Header />
      <Grid container sx={{height: "calc(100vh - 128px)", marginTop: "64px"}}>
        <Grid item xs={6}>
          <Box sx={{height: "calc(100vh - 64px)", width: "100%"}}>
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{field: "createdAt", sort: "desc"}],
                },
              }}
              rows={dataToRows(data.projects)}
              columns={columns}
              editMode={"cell"}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          {project && (
            <Box
              sx={{
                height: "100vh",
                width: "100%",
                overflow: "scroll",
                padding: 1,
              }}>
              <Typography variant="h6">brand</Typography>
              {Object.keys(brand).map((k) => (
                <Typography key={k}>
                  <b>{k}</b> {brand[k]}
                </Typography>
              ))}
              <Divider />
              <Typography variant="h6">inquiry</Typography>
              {inquiry.map((item, i) => (
                <Typography key={i}>
                  {item.type === "question" && (
                    <>
                      <br />
                      <b> {item.value.toString()} </b>
                    </>
                  )}
                  {item.type !== "question" && item.value.toString()}
                </Typography>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}
export default withBasicProviders(AuthProvider)(Projects);
