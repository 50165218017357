import {createClient} from "contentful";

const client = createClient({
  space: `${process.env.CONTENTFUL_SPACE}`,
  accessToken: `${process.env.CONTENTFUL_ACCESS_TOKEN}`,
  environment: process.env.CONTENTFUL_ENVIRONMENT,
});

export async function getPage(searchedPage) {
  const pages = await client.getEntries({
    limit: 999,
    content_type: "pageStaticAlert",
  });

  const cleaned = pages.items.map((e) => e.fields);
  const filtered = cleaned.filter((e) => e.page === searchedPage);
  return filtered?.[0];
}

let entryCache = {};
export async function getEntry(id) {
  if (entryCache[id]) {
    return entryCache[id];
  }
  const entry = await client.getEntry(id, {include: 2});
  entryCache[id] = entry;
  return entry;
}
