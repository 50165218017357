import React, {useContext, useEffect, useState} from "react";
import {withBasicProviders} from "../provider/withBasicProviders";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import useFetch from "use-http";
import {API} from "../config";
import AuthProvider, {authContext, useAuth} from "../provider/AuthProvider";
import Header from "../components/Header";

import Plot from "react-plotly.js";

function toX(data) {
  return Object.keys(data)
    .map((day) => day)
    .filter((day) => day !== "sum");
}

function toCreator(data) {
  return Object.keys(data["sum"])
    .map((creator) => creator)
    .sort((a, b) => (data["sum"][a] > data["sum"][b] ? 1 : -1))
    .filter((creator) => creator !== "klaus");
}

function toData(data) {
  const creators = toCreator(data);
  return creators.map((creator) => ({
    x: toX(data),
    y: toX(data).map((day) => data[day][creator]),
    text: toX(data).map((day) => data[day][creator]),
    type: "bar",
    name: `${creator} (${data["sum"][creator]})`,
    mode: "lines+markers",
  }));
}

function Stats(props) {
  const {auth, authed} = useContext(authContext);

  const options = {
    headers: {
      Authorization: `Basic ${auth}`,
    },
  };

  const {loading, error, data} = useFetch(`${API}/manage/stats`, options, []);

  if (!authed) {
    return null;
  }
  if (!data) {
    return <LinearProgress />;
  }

  return (
    <>
      <Header />
      <Box container sx={{height: "calc(100vh - 128px)", marginTop: "64px"}}>
        <Plot
          data={toData(data)}
          style={{width: "100%", height: "100%"}}
          layout={{
            barmode: "stack",
            autosize: true,
          }}
        />
      </Box>
    </>
  );
}
export default withBasicProviders(AuthProvider)(Stats);
