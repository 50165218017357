import React, {useContext, useEffect, useState} from "react";
import {withBasicProviders} from "../provider/withBasicProviders";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import AuthProvider, {authContext} from "../provider/AuthProvider";
import {useNavigate} from "react-router-dom";
import Header from "../components/Header";

function Home(props) {
  const {authed} = useContext(authContext);

  const [fallback, setFallback] = useState(false);

  useEffect(() => {
    setTimeout(() => setFallback(true), 1000);
  }, []);

  const navigate = useNavigate();

  if (!authed && fallback) {
    return (
      <Button variant="contained" onClick={() => navigate("/login")}>
        Login
      </Button>
    );
  }

  if (!authed) {
    return <LinearProgress />;
  }

  return (
    <>
      <Header />
      <Box>
        <Typography variant="h1" align="center" sx={{marginTop: "50vh"}}>
          Hi.
        </Typography>
      </Box>
    </>
  );
}

export default withBasicProviders(AuthProvider)(Home);
