import React from "react";
import mermaid from "mermaid";

import "./mermaid.css";
//https://github.com/mermaid-js/mermaid/blob/develop/docs/Setup.md
mermaid.initialize({
  startOnLoad: true,
  theme: "dark",
  maxTextSize: 900000,
  securityLevel: "loose",
  themeVariables: {
    fontFamily: "inherit",
  },
  flowchart: {
    htmlLabels: true,
    curve: "basis",
    useMaxWidth: false,
  },
});

export default class Mermaid extends React.Component {
  componentDidMount() {
    mermaid.contentLoaded();
  }
  render() {
    return <div className="mermaid">{this.props.chart}</div>;
  }
}
